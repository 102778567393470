@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
*{
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
/* Hide scrollbar */
.main .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  
  .main .scrollbar-hidden {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .shimmer {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 47.4% 11.2%;
   
      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;
   
      --popover: 0 0% 100%;
      --popover-foreground: 222.2 47.4% 11.2%;
   
      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
   
      --card: 0 0% 100%;
      --card-foreground: 222.2 47.4% 11.2%;
   
      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;
   
      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;
   
      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;
   
      --destructive: 0 100% 50%;
      --destructive-foreground: 210 40% 98%;
   
      --ring: 215 20.2% 65.1%;
   
      --radius: 0.5rem;
    }
   
    .dark {
      --background: 224 71% 4%;
      --foreground: 213 31% 91%;
   
      --muted: 223 47% 11%;
      --muted-foreground: 215.4 16.3% 56.9%;
   
      --accent: 216 34% 17%;
      --accent-foreground: 210 40% 98%;
   
      --popover: 224 71% 4%;
      --popover-foreground: 215 20.2% 65.1%;
   
      --border: 216 34% 17%;
      --input: 216 34% 17%;
   
      --card: 224 71% 4%;
      --card-foreground: 213 31% 91%;
   
      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 1.2%;
   
      --secondary: 222.2 47.4% 11.2%;
      --secondary-foreground: 210 40% 98%;
   
      --destructive: 0 63% 31%;
      --destructive-foreground: 210 40% 98%;
   
      --ring: 216 34% 17%;
   
      --radius: 0.5rem;
    }
  }
   
  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
      font-feature-settings: "rlig" 1, "calt" 1;
    }
  }
  
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  